<template>
<div class="card-box-full">
  <div class="card-box-header">
    Sender Override
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="errorMessage">
    <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorMessage }}</div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-if="isRefreshing">
    <div class="spinner-border spinner-border-sm text-primary" role="status" style="margin: 13px 0px 0px 10px">
      <span class="sr-only"></span>
    </div>
  </div>

  <!-- ----------------------------------------------------------------- -->
  <div v-else-if="results.length > 0">

    <CTabs variant="tabs" :active-tab="0" style="margin-top: 15px">
      <CTab title="Approved">
        <div class="tab-box" style="padding: 10px">

          <table class="table table-responsive-sm" style="margin-top: 0px; margin-bottom: -10px;">
            <thead>
              <tr style="font-size: 13px; border: 2px solid transparent">
                <th>
                  <div style="cursor: pointer" @click="clickedSort('sender')">
                    Sender
                    <span v-if="sortColumn == 'sender'">
                      <span v-if="sortOrder == 'asc'">▲</span>
                      <span v-if="sortOrder == 'desc'">▼</span>
                    </span>
                  </div>
                </th>
                <th style="text-align:center">
                  <div style="cursor: pointer" @click="clickedSort('action')">
                    Action
                    <span v-if="sortColumn == 'action'">
                      <span v-if="sortOrder == 'asc'">▲</span>
                      <span v-if="sortOrder == 'desc'">▼</span>
                    </span>
                  </div>
                </th>
                <th>
                  <div style="cursor: pointer" @click="clickedSort('description')">
                    Description
                    <span v-if="sortColumn == 'description'">
                      <span v-if="sortOrder == 'asc'">▲</span>
                      <span v-if="sortOrder == 'desc'">▼</span>
                    </span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="result in results" style="font-size: 13px" class="clickable-row" @click="clickedResult(result)">
                <td width="120">
                  <div style="width: 120px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; color: #3A9CFC; font-weight: 700">
                    {{ result.sender }}
                  </div>
                </td>
                <td align="center" width="80">
                  <span v-if="result.is_spam" class="badge badge-pill badge-danger">
                    Block
                  </span>
                  <span v-else class="badge badge-pill badge-success">
                    Allow
                  </span>
                </td>
                <td width="460">
                  <div style="width: 460px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
                    {{ result.description }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </CTab>
      <!-- <CTab title="Pending">
        <div class="tab-box" style="padding: 10px">

          <div class="alert alert-info" style="margin-top: 15px; margin-bottom: 5px" role="alert">
            No overrides are waiting to be approved
          </div>

        </div>
      </CTab> -->
    </CTabs>
  </div>

  <CModal title="Sender Override" :color="selectedResult.block ? 'danger' : 'success'" :show.sync="selectedResult.show">

    <div style="margin: 0px 0px 15px 0px; font-size: 12px">
      Overrided by <account :uuid="selectedResult.createdBy" style="font-weight: 500"></account>
      <timeago :datetime="selectedResult.created"></timeago>
      <div v-if="selectedResult.approvedBy" style="font-size: 12px">
        Approved by <account :uuid="selectedResult.approvedBy" style="font-weight: 500"></account>
        <timeago :datetime="selectedResult.approved"></timeago>
      </div>
    </div>

    <div class="rounded-box">
      <table width="100%">
        <tr>
          <td width="100" height="45"><b>Sender</b></td>
          <td align="left" style="font-size: 18px; font-weight: 500;">
            <div class="clickable-text" @click="clickedSender(selectedResult.sender)">
              {{ selectedResult.sender }}
            </div>
          </td>
        </tr>
        <tr>
          <td height="45"><b>Action</b></td>
          <td>
            <CDropdown :color="selectedResult.block ? 'danger' : 'success'" :toggler-text="selectedResult.action" :disabled="isOverriding">
              <CDropdownItem @click="clickedSelectAction('block')">Block</CDropdownItem>
              <CDropdownItem @click="clickedSelectAction('allow')">Allow</CDropdownItem>
            </CDropdown>
          </td>
        </tr>
        <tr>
          <td height="45"><b>Description</b></td>
          <td align="right">
            <input type="text" style="width: 100%" class="form-control" placeholder="Describe who the sender is" :value="selectedResult.description" :disabled="isOverriding">
          </td>
        </tr>
      </table>
    </div>

    <div v-if="errorOverride">
      <div class="alert alert-danger" style="margin-top: 15px; margin-bottom: 5px" role="alert">{{ errorOverride }}</div>
    </div>

    <template #footer>

      <CButton @click="selectedResult.show = false" color="secondary" style="width: 100px; margin-top: 5px" :disabled="isOverriding">
        Cancel
      </CButton>

      <CButton @click="override(selectedResult.sender, selectedResult.block, selectedResult.description)" :color="selectedResult.block ? 'danger' : 'success'" style="width: 150px; margin-top: 5px" :disabled="isOverriding">
        <div v-if="isOverriding">
          <div class="spinner-border spinner-border-sm text-secondary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
        <div v-else>
        <span v-if="selectedResult.block">Block this sender</span>
        <span v-else>Allow this sender</span>
      </div>
      </CButton>

    </template>
  </CModal>

</div>
</template>

<script>
export default {
  name: 'Sender-Override',
  data() {
    return {
      errorMessage: null,
      errorOverride: null,
      isOverriding: false,
      isRefreshing: false,
      results: [],
      sortColumn: "sender",
      sortOrder: "asc",
      selectedResult: {
        action: "",
        approved: null,
        approvedBy: null,
        block: false,
        created: null,
        createdBy: null,
        description: "",
        show: false,
        sender: null
      },
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    clickedResult(result) {

      this.$router.push({
        path: '/sms-profile/sender/' + encodeURIComponent(result.sender)
      });

      // this.selectedResult.approved = result.approved;
      // this.selectedResult.approvedBy = result.approved_by;
      // this.selectedResult.created = result.created;
      // this.selectedResult.createdBy = result.created_by;
      // this.selectedResult.description = result.description;
      // this.selectedResult.sender = result.sender;
      // this.selectedResult.block = result.is_spam;
      // this.selectedResult.show = true;
      // this.errorOverride = null;
      //
      // if (result.is_spam) {
      //   this.clickedSelectAction("block");
      // } else {
      //   this.clickedSelectAction("allow");
      // }
    },
    //--------------------------------------------------------------------------
    clickedSelectAction(action) {
      if (action.toLowerCase() == "block") {
        this.selectedResult.action = "Block";
        this.selectedResult.block = true;
      } else {
        this.selectedResult.action = "Allow";
        this.selectedResult.block = false;
      }
    },
    //--------------------------------------------------------------------------
    clickedSender(sender) {
      this.$router.push({
        path: '/sms-profile/sender/' + encodeURIComponent(sender)
      });
    },
    //--------------------------------------------------------------------------
    clickedSort(column) {
      if (column != this.sortColumn) {
        this.sortColumn = column;
        this.sortResults();
        return;
      }
      if (this.sortOrder == "asc") {
        this.sortOrder = "desc";
      } else {
        this.sortOrder = "asc";
      }
      this.sortResults();
    },
    //--------------------------------------------------------------------------
    override(sender, block, description) {
      if (this.isOverriding) {
        return;
      } else {
        this.errorOverride = null;
        this.isOverriding = true;
      }

      var self = this;
      setTimeout(function() {
        self.errorOverride = "Failed to override sender";
        self.isOverriding = false;
      }, 1000);
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.overridden_senders) {
        this.errorMessage = "No results were returned";
        return;
      }
      this.results = response.overridden_senders;
      this.sortResults();
    },
    //--------------------------------------------------------------------------
    refresh() {
      // Make sure we're not currently refreshing
      if (this.isRefreshing) {
        return;
      } else {
        this.errorMessage = null;
        this.isRefreshing = true;
        this.results = [];
      }

      // Fetch the recent messages
      var that = this;
      var method = "GET";
      var url = "https://sms-filter.platform.robocalls.ai/overridden-senders";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, null, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isRefreshing = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isRefreshing = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isRefreshing = false;
          that.errorMessage = error;
        }
      })
    },
    //--------------------------------------------------------------------------
    sortResults() {
      var collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base'
      });
      var order = this.sortOrder;

      //------------------------------------------------------
      if (this.sortColumn == "sender") {
        this.results.sort(function(a, b) {
          if (order == "asc") {
            return collator.compare(a.sender, b.sender);
          } else {
            return collator.compare(b.sender, a.sender);
          }
        });
        //------------------------------------------------------
      } else if (this.sortColumn == "action") {
        this.results.sort(function(a, b) {
          if (order == "asc") {
            if (a.is_spam === b.is_spam) {
              //return 0;
              return collator.compare(a.sender, b.sender);
            } else if (a.is_spam) {
              return -1
            } else {
              return 1
            }
          } else {
            if (a.is_spam === b.is_spam) {
              //return 0;
              return collator.compare(a.sender, b.sender);
            } else if (b.is_spam) {
              return -1
            } else {
              return 1
            }
          }
        });
        //------------------------------------------------------
      } else if (this.sortColumn == "description") {
        this.results.sort(function(a, b) {
          if (order == "asc") {
            return collator.compare(a.description, b.description);
          } else {
            return collator.compare(b.description, a.description);
          }
        });
      }
    }
  },
  mounted() {
    this.refresh();
  }
}
</script>
